import React from 'react';
import { combineRules } from '@mentimeter/ragnar-react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import type { Rule } from '@mentimeter/ragnar-styled';
import { styled } from '@mentimeter/ragnar-styled';
import type { DSC } from '@mentimeter/ragnar-dsc';
import type { ComponentProps } from 'react';
import { styledTextRule } from '../text';

const rule: Rule<{
  theme: DSC;
  required?: boolean;
  disabled?: boolean;
}> = () => ({});

export const FelaLabel = styled('label', {
  displayName: 'Label',
})(
  combineRules(styledTextRule, rule),
  'letterSpacing',
  'textAlign',
  'flex',
  'layout',
  'display',
  'color',
  'kosmosLineHeight',
  'kosmosFontSize',
  'kosmosSpacing',
);

export type LabelT = ComponentProps<typeof FelaLabel>;

export const Label = React.forwardRef((props: LabelT, ref) => {
  const { className, ...rest } = props;

  const defaultClasses = [
    'r-label',
    'inline-block',
    'font-body',
    'font-semibold',
    'text-87.5',
    'leading-tight',
    'mb-3',
    'cursor-pointer',
    'text',
  ];
  if (props.disabled) {
    defaultClasses.push('cursor-not-allowed', 'text-disabled');
  }
  if (props.required) {
    defaultClasses.push('after:content-["*"]', 'after:text-negative');
  }

  return (
    <FelaLabel {...rest} ref={ref} className={cn(defaultClasses, className)} />
  );
});
