import React from 'react';
import {
  styled,
  LAYOUT,
  POSITION,
  FLEX_CONTAINER,
} from '@mentimeter/ragnar-styled';
import type { ComponentProps } from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { rule as boxRule } from '../box';

type ListType = '1' | 'A' | 'a' | 'I' | 'i' | 'none';

const FelaList = styled('ul', { displayName: 'List' })(
  boxRule,
  ...[...LAYOUT, ...FLEX_CONTAINER, ...POSITION],
  'color',
  'kosmosSpacing',
);

export interface ListT extends ComponentProps<typeof FelaList> {
  type?: ListType;
}

export const List = React.forwardRef((props: ListT, ref) => {
  const { className, as = 'ul', type, ...rest } = props;

  const MAP_LIST_TYPE = {
    '1': 'list-dec',
    A: 'list-upper-alpha',
    a: 'list-lower-alpha',
    I: 'list-upper-roman',
    i: 'list-lower-roman',
    none: 'list-none',
  };

  const defaultClasses = [
    'r-box', // Reset selector
    'flex',
    'flex-col',
    'flex-none',
    'items-start',
    'min-w-0',
    'min-h-0',
    'relative',
    'w-auto',
    'list-inside',
    '[&_ul]:ml-2',
    '[&_ol]:ml-2',
    type && MAP_LIST_TYPE[type],
  ];

  return (
    <FelaList
      as={as}
      ref={ref}
      className={cn(defaultClasses, className)}
      {...rest}
    />
  );
});
