import * as React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Dismiss } from '../dismiss';
import { Text } from '../text';
import { Group, type GroupActionT, type GroupT } from '../group';
import { Box, type BoxT } from '../box';
import type { DismissT } from '../dismiss';
import { variants } from './CalloutCardGeneric.variants';
import type { CalloutCardThemeType } from './calloutCardGeneric.types';

export interface CalloutCardGenericT
  extends Omit<BoxT, 'title'>,
    Pick<DismissT, 'onDismiss'>,
    Pick<GroupT, 'actionPattern'>,
    Pick<GroupActionT, 'actions'> {
  theme?: CalloutCardThemeType | undefined;
  title?: React.ReactNode;
  compact?: boolean | undefined;
}

export const CalloutCardGeneric = ({
  title,
  actions,
  onDismiss,
  theme = 'brand',
  actionPattern = 'z',
  children,
  compact = false,
  className,
  ...rest
}: CalloutCardGenericT) => {
  const currentSize = compact ? 'compact' : 'default';

  const containerClasses = variants.container({
    theme,
    size: currentSize,
    hasOnDismiss: Boolean(onDismiss),
  });

  const headingClasses = variants.heading({
    size: currentSize,
  });

  const textClasses = variants.text({
    size: currentSize,
  });

  return (
    <Box className={clsx(containerClasses, className)} {...rest}>
      {onDismiss && (
        <Box className="absolute top-0 right-0">
          <Dismiss onDismiss={onDismiss} dismissLabel="Close Notification" />
        </Box>
      )}
      {typeof title === 'string' ? (
        <Text as="h1" className={headingClasses}>
          {title}
        </Text>
      ) : (
        title
      )}

      {typeof children === 'string' ? (
        <Text as="p" className={textClasses}>
          {children}
        </Text>
      ) : (
        children
      )}

      <Group
        actions={actions}
        size={compact ? 'compact' : 'default'}
        actionPattern={actionPattern}
        className="mt-3"
      />
    </Box>
  );
};
