import * as React from 'react';
import { Text } from '../text';
import { Markdown } from '../markdown';
import { variants } from './CalloutCardGeneric.variants';
import {
  CalloutCardGeneric,
  type CalloutCardGenericT,
} from './CalloutCardGeneric';

export interface CalloutCardT extends CalloutCardGenericT {
  children?: React.ReactNode;
  description?: string;
  enableMarkdown?: boolean;
}

export const CalloutCard = ({
  description,
  enableMarkdown,
  children,
  ...rest
}: CalloutCardT) => {
  const textClasses = variants.text({
    size: rest.compact ? 'compact' : 'default',
  });

  return (
    <CalloutCardGeneric {...rest}>
      {description ? (
        enableMarkdown ? (
          <Text className={textClasses}>
            <Markdown>{description}</Markdown>
          </Text>
        ) : (
          description
        )
      ) : (
        children
      )}
    </CalloutCardGeneric>
  );
};
