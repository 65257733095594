globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"20cd70929e3aaf4370efdc34ebdad5ac3f7d882f"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "/auth";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import {
  standardSentryConfig,
  extraClientConfig,
} from '@mentimeter/sentry-config';
Sentry.init({
  dsn: 'https://809f4f98d06c59972b6aed630771e732@o866780.ingest.us.sentry.io/4506869648850944',
  ...standardSentryConfig,
  ...extraClientConfig,
});
