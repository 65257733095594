import React from 'react';
import type { TooltipContentProps } from '@radix-ui/react-tooltip';
import { TooltipTrigger } from './TooltipPrimitives';
import { TooltipBase } from './TooltipBase';
import { TooltipContainer, type TooltipContainerT } from './TooltipContainer';

export type TextTransform =
  | 'uppercase'
  | 'lowercase'
  | 'capitalize'
  | 'normal-case';

export type TooltipSideT = Pick<TooltipContentProps, 'side'>;
export interface TooltipT extends TooltipContainerT {
  trigger: React.ReactNode;
  delayDuration?: number;
}

export const Tooltip = ({
  trigger,
  delayDuration = 200,
  withPortal = true,
  children,
  ...props
}: TooltipT): React.ReactNode => {
  return children ? (
    <TooltipBase delayDuration={delayDuration}>
      <TooltipTrigger asChild>{trigger}</TooltipTrigger>
      <TooltipContainer withPortal={withPortal} {...props}>
        {children}
      </TooltipContainer>
    </TooltipBase>
  ) : (
    trigger
  );
};
