import React from 'react';
import type { TooltipProps } from '@radix-ui/react-tooltip';
import { TooltipProvider, TooltipRoot } from './TooltipPrimitives';

interface TooltipBaseT extends TooltipProps {
  children: React.ReactNode;
}

export const TooltipBase = ({ children, ...props }: TooltipBaseT) => {
  return (
    <TooltipProvider>
      <TooltipRoot delayDuration={props.delayDuration || 200} {...props}>
        {children}
      </TooltipRoot>
    </TooltipProvider>
  );
};
