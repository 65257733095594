import React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import type {
  TooltipArrowProps,
  TooltipContentProps,
  TooltipPortalProps,
  TooltipProps,
  TooltipProviderProps,
  TooltipTriggerProps,
} from '@radix-ui/react-tooltip';

export const TooltipProvider = ({ ...props }: TooltipProviderProps) => (
  <TooltipPrimitive.Provider {...props} />
);
export const TooltipRoot = ({ ...props }: TooltipProps) => (
  <TooltipPrimitive.Root {...props} />
);
export const TooltipTrigger = ({ ...props }: TooltipTriggerProps) => (
  <TooltipPrimitive.Trigger {...props} />
);
export const TooltipPortal = ({ ...props }: TooltipPortalProps) => (
  <TooltipPrimitive.Portal {...props} />
);
export const TooltipArrow = ({ ...props }: TooltipArrowProps) => (
  <TooltipPrimitive.Arrow {...props} />
);

export const TooltipContent = React.forwardRef<
  HTMLDivElement,
  TooltipContentProps
>(({ ...props }, forwardedRef) => {
  return <TooltipPrimitive.Content ref={forwardedRef} {...props} />;
});
