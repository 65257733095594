import * as React from 'react';
import { styled } from '@mentimeter/ragnar-styled';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import toArray from 'lodash/toArray';
import { rule as boxRule } from '../box';
import { ScreenReaderOnly } from '../screenreader-only';
import {
  characterCount,
  type CharacterCountVariantProps,
} from './CharacterCount.variants';

export interface CharacterCountT
  extends Pick<CharacterCountVariantProps, 'multiline' | 'iconTrailing'> {
  value: string;
  maxLength: number;
  id?: string | undefined;
  className?: string | undefined;
}

export const stringToCharacterCount = (value: string) => {
  return toArray(value).length;
};

const CharacterCountC = ({
  value = '',
  maxLength,
  className,
  id,
  multiline,
  iconTrailing,
}: CharacterCountT) => {
  const charactersTyped = stringToCharacterCount(value);
  const percentage = charactersTyped / maxLength;
  const count = maxLength - charactersTyped;
  const color = percentage > 0.5 ? 'negative' : 'default';

  return (
    <span
      className={cn(
        characterCount({ color, iconTrailing, multiline }),
        className,
      )}
      id={id}
      aria-live="polite"
      aria-atomic="true"
    >
      {count < 0 ? 0 : count!}
      <ScreenReaderOnly> characters left</ScreenReaderOnly>
    </span>
  );
};

export const CharacterCount = styled(CharacterCountC, {
  displayName: 'CharacterCount',
})(boxRule, 'kosmosSpacing');
