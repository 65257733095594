import type { ReactNode } from 'react';
import * as React from 'react';
import { RagnarMarkdown } from '@mentimeter/ragnar-markdown';
import {
  cva,
  clsx,
  type VariantProps,
} from '@mentimeter/ragnar-tailwind-config';
import { Text } from '../text';
import { List } from '../list';
import { MarkdownLink } from './MarkdownLink';

type AllowedTypes =
  | 'text'
  | 'paragraph'
  | 'heading'
  | 'strong'
  | 'emphasis'
  | 'list'
  | 'listItem'
  | 'thematicBreak'
  | 'link'
  | 'image';

export interface MarkdownT {
  children: string;
  allowedTypes?: Array<AllowedTypes>;
}

const BLOCK_SPACING = 'mb-2';
const MIN_HEADING_LEVEL = 2;
const MAX_HEADING_LEVEL = 4;

export const MarkdownText = ({ children }: { children: ReactNode }) => children;

export const MarkdownParagraph = ({ children }: { children: ReactNode }) => (
  <Text
    as="p"
    className={clsx([
      BLOCK_SPACING,
      'text-inherit',
      'text-size-inherit',
      'last:mb-0',
    ])}
  >
    {children}
  </Text>
);

const variants = cva([], {
  variants: {
    level: {
      2: 'text-125',
      3: 'text-112.5',
      4: 'text-100',
    },
  },
  defaultVariants: {
    level: 2,
  },
});

const clamp = (num: number, min: number, max: number) =>
  Math.min(Math.max(num, min), max) as VariantProps<typeof variants>['level'];

export const MarkdownHeading = ({
  children,
  level,
}: {
  level: number;
  children: ReactNode;
}) => {
  // We increase the level by one to start at an h2 since most pages should have their own h1 already.
  const HEADING_LEVEL = clamp(level + 1, MIN_HEADING_LEVEL, MAX_HEADING_LEVEL);
  const levelVariants = variants({ level: HEADING_LEVEL });

  return (
    <Text
      as={`h${HEADING_LEVEL}`}
      className={clsx([
        levelVariants,
        BLOCK_SPACING,
        'mt-4',
        'text-inherit',
        'last:mb-0',
      ])}
    >
      {children}
    </Text>
  );
};

export const MarkdownStrong = ({ children }: { children: ReactNode }) => (
  <strong className="text-inherit text-size-inherit font-semibold">
    {children}
  </strong>
);

export const MarkdownEmphasis = ({ children }: { children: ReactNode }) => (
  <em className="text-inherit text-size-inherit italic">{children}</em>
);

export const MarkdownList = ({
  ordered,
  children,
}: {
  ordered: boolean;
  children: ReactNode;
}) => {
  return (
    <List
      className={clsx([BLOCK_SPACING, 'ml-8', 'last:mb-0'])}
      as={ordered ? 'ol' : 'ul'}
    >
      {children}
    </List>
  );
};

export const MarkdownListItem = ({ children }: { children: ReactNode }) => (
  <Text as="li" className="text-inherit text-size-inherit">
    {children}
  </Text>
);

const RENDERERS = {
  text: MarkdownText,
  paragraph: MarkdownParagraph,
  heading: MarkdownHeading,
  strong: MarkdownStrong,
  emphasis: MarkdownEmphasis,
  list: MarkdownList,
  listItem: MarkdownListItem,
  link: MarkdownLink,
};

/**
 * ## Markdown
 *
 * NB! Javascript links are not allowed anymore.
 *
 * @param {string} children the string to be formatted
 * @param {AllowedTypes} allowedTypes all types are allowed on default. To restrict amount of types, set them manually. The types are: text, paragraph, heading, strong, emphasis, list, listItem, thematicBreak, link
 *
 * ### More on `react-markdown` security from [their docs](https://github.com/remarkjs/react-markdown#uritransformer)
 * `uriTransformer` is the default URL transform, which you can overwrite. It’s given a URL and cleans it, by allowing only http:, https:, mailto:, and tel: URLs, absolute paths (/example.png), and hashes (#some-place).
 */

export const Markdown = ({
  children,
  allowedTypes = [
    'text',
    'paragraph',
    'heading',
    'strong',
    'emphasis',
    'list',
    'listItem',
    'thematicBreak',
    'link',
  ],
}: MarkdownT) => (
  <RagnarMarkdown
    renderers={RENDERERS}
    source={children}
    allowedTypes={allowedTypes}
    unwrapDisallowed
  />
);
