import * as React from 'react';
import { QuestionMarkCircleIcon } from '@mentimeter/ragnar-visuals';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import type { ColorsEnum } from '@mentimeter/ragnar-colors';
import { Clickable } from '../clickable';

export interface TooltipDescriptiveT {
  id?: string;
  color?: ColorsEnum;
  className?: string;
}

export const TriggerDescriptive = React.forwardRef<
  HTMLDivElement,
  TooltipDescriptiveT
>((props, ref) => {
  const { color = 'primary', id, className, ...rest } = props;

  return (
    <Clickable
      ref={ref}
      data-testid={`tooltip-${id}`}
      className={cn(
        'rounded-full cursor-pointer inline-flex ml-2 justify-center items-center self-center',
        className,
      )}
      {...rest}
    >
      <QuestionMarkCircleIcon color={color} />
    </Clickable>
  );
});
